<template>
  <div class="row">
    <div class="form-group col-md-12 text-right">
      <button
        type="button"
        class="btn btn-primary btn-sm"
        data-toggle="modal"
        data-target="#modal_detalle"
        @click="getDatosModal()"
        v-if="$store.getters.can('tep.nominaciones.edit')"
      >
        <i class="fas fa-plus"></i>
      </button>
    </div>

    <table
      class="
        table table-bordered table-striped table-hover
        text-nowrap
        table-sm
      "
    >
      <thead class="thead-dark">
        <tr>
          <th class="text-center">Tipo Operación</th>
          <th class="text-center">Tipo de Vehículo</th>
          <th class="text-center">Transportadora</th>
          <th class="text-center">Turno</th>
          <th class="text-center">Cantidad</th>
          <th class="text-center" style="width: 120px">Acción</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="detalle in $parent.nominacion.tep_det_nominacion"
          :key="detalle.id"
        >
          <td>
            <i
              v-if="detalle.tep_itinerario_id && detalle.tep_nominacion_id"
              class="fas fa-circle text-primary mr-2"
            ></i>
            {{ detalle.nTipoOperacion }}
            <strong>{{ detalle.area ? `(${detalle.nArea})` : "" }}</strong>
          </td>
          <td>{{ detalle.tep_tipos_vehiculos.nombre }}</td>
          <td>{{ detalle.tep_transportadora.razon_social }}</td>
          <td>{{ detalle.turno.nombre }}</td>
          <td class="text-center">{{ detalle.cantidad_vehiculo }}</td>
          <td class="text-right">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-sm bg-navy"
                data-toggle="modal"
                data-target="#modal_detalle"
                @click="getDatosModal(detalle)"
                v-if="$store.getters.can('tep.nominaciones.edit')"
              >
                <i class="fas fa-edit"></i>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-danger"
                @click="destroy(detalle)"
                v-if="
                  $store.getters.can('tep.nominaciones.edit') &&
                    $parent.permite_editar
                "
              >
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      class="modal fade"
      id="modal_detalle"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Detalle</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-4">
                <label>Tipo Operación</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.tipo_operacion"
                  :class="
                    $v.form.tipo_operacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  :disabled="form.id"
                  @change="validaRepetido()"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_operacion in $parent.listasForms.tipo_operacion"
                    :key="tipo_operacion.numeracion"
                    :value="tipo_operacion.numeracion"
                  >
                    {{ tipo_operacion.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-8">
                <div v-if="form.tipo_operacion && form.tipo_operacion == 2">
                  <label>Área</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="form.area"
                    :class="$v.form.area.$invalid ? 'is-invalid' : 'is-valid'"
                    :disabled="form.id"
                    @change="validaRepetido()"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="area in $parent.listasForms.areas"
                      :key="area.numeracion"
                      :value="area.numeracion"
                    >
                      {{ area.descripcion }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Tipo Vehículo</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.tipo_vehiculo"
                  :class="
                    $v.form.tipo_vehiculo.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  :disabled="form.id"
                  @change="validaRepetido()"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_vehiculo in $parent.listasForms.tipo_vehiculo"
                    :key="tipo_vehiculo.id"
                    :value="tipo_vehiculo.id"
                  >
                    {{ tipo_vehiculo.nombre }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>Turno</label>
                <v-select
                  :class="[
                    $v.form.turno.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.turno"
                  placeholder="Turno"
                  label="nombre"
                  :options="$parent.listasForms.turnos"
                  class="form-control form-control-sm p-0"
                  :disabled="form.id"
                  @input="validaRepetido()"
                ></v-select>
              </div>
              <div class="form-group col-md-8">
                <label>Transportadora</label>
                <v-select
                  :class="[
                    $v.form.transportadora.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.transportadora"
                  placeholder="Transportadora"
                  label="razon_social"
                  :options="$parent.listasForms.transportadoras"
                  class="form-control form-control-sm p-0"
                  :disabled="form.id"
                  @input="validaRepetido()"
                ></v-select>
              </div>
              <div class="form-group col-md-4">
                <label>Cantidad de vehículos</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  v-model="form.cantidad_vehiculo"
                  :class="
                    $v.form.cantidad_vehiculo.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @blur="validaLimiteVehiculos()"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="
                $store.getters.can('tep.nominaciones.edit') && !$v.form.$invalid
              "
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";

export default {
  name: "NominacionDetalles",
  components: {
    vSelect,
  },
  data() {
    return {
      cargando: false,
      limite_vehiculos: null,
      form: {
        id: null,
        tep_nominacion_id: this.$parent.nominacion.id,
        tipo_operacion: null,
        area: null,
        tipo_vehiculo: null,
        cantidad_vehiculo: null,
        turno: null,
        transportadora: null,
      },
      listasForms: {
        sitios: [],
        estados: {},
      },
    };
  },
  validations() {
    if (this.form.tipo_operacion != 2) {
      return {
        form: {
          tipo_operacion: {
            required,
          },
          tipo_vehiculo: {
            required,
          },
          cantidad_vehiculo: {
            required,
          },
          transportadora: {
            required,
          },
          turno: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          tipo_operacion: {
            required,
          },
          area: {
            required,
          },
          tipo_vehiculo: {
            required,
          },
          cantidad_vehiculo: {
            required,
          },
          transportadora: {
            required,
          },
          turno: {
            required,
          },
        },
      };
    }
  },

  methods: {
    getDatosModal(detalle = null) {
      this.resetForm();
      if (detalle) {
        this.form = {
          id: detalle.id,
          tep_nominacion_id: this.$parent.nominacion.id,
          tipo_operacion: detalle.tipo_operacion,
          area: detalle.area,
          tipo_vehiculo: detalle.tep_tipos_vehiculos.id,
          cantidad_vehiculo: detalle.cantidad_vehiculo,
          turno: detalle.turno,
          transportadora: detalle.tep_transportadora,
        };
        this.limite_vehiculos = detalle.cantidad_vehiculo;
      }
    },

    async validaRepetido() {
      const form = this.form;
      await this.$parent.getTurnos(form.transportadora.id);
      if (
        form.tipo_operacion &&
        form.tipo_vehiculo &&
        form.turno &&
        form.transportadora &&
        form.area
      ) {
        const data = this.$parent.nominacion.tep_det_nominacion;
        data.forEach((detalle) => {
          if (
            form.tipo_operacion == detalle.tipo_operacion &&
            form.tipo_vehiculo == detalle.tipo_vehiculo &&
            form.turno.id == detalle.turno_id &&
            form.transportadora.id == detalle.empresa_id &&
            form.area == detalle.area
          ) {
            form.transportadora = null;
            this.$swal({
              icon: "error",
              title: `Ya existe un detalle nominación con los datos ingresados.`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return false;
          }
        });
      }

      // Se valida si se debe dejar el campo de área diligenciado
      if (form.tipo_operacion && form.tipo_operacion != 2) {
        form.area = null;
      }
    },

    validaLimiteVehiculos() {
      if (this.limite_vehiculos) {
        if (
          this.form.cantidad_vehiculo < this.limite_vehiculos &&
          !this.$parent.permite_editar
        ) {
          this.$swal({
            icon: "error",
            title: `Se debe postular una cantidad mayor de vehículos a la ya existente.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.form.cantidad_vehiculo = this.limite_vehiculos;
        }
      }
    },

    resetForm() {
      this.limite_vehiculos = null;
      this.form = {
        id: null,
        tep_nominacion_id: this.$parent.nominacion.id,
        tipo_operacion: null,
        area: null,
        tipo_vehiculo: null,
        cantidad_vehiculo: null,
        transportadora: null,
        turno: null,
      };
    },

    // Acciones de formualrios
    save() {
      this.$parent.cargando = true;
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        this.form.empresa_id = this.form.transportadora.id;
        this.form.turno_id = this.form.turno.id;
        axios({
          method: "POST",
          url: "/api/tep/nominaciones/detalle",
          data: this.form,
        })
          .then((response) => {
            this.$parent.cargando = false;
            this.$refs.closeModal.click();
            this.$parent.nominacion.tep_det_nominacion =
              response.data.tep_det_nominacion;
            this.$parent.cant_detalles =
              response.data.tep_det_nominacion.length;
            this.$swal({
              icon: "success",
              title:
                "Se actualizó el detalle de la nominación correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: `Ha ocurrido un error: ${e.response.data.message}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(detalle) {
      this.$swal({
        title: "Esta seguro de eliminar el detalle de nominación?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.cargando = true;
          axios
            .delete("/api/tep/nominaciones/detalle/" + detalle.id)
            .then((response) => {
              this.$parent.cargando = false;
              this.$parent.nominacion.tep_det_nominacion =
                response.data.tep_det_nominacion;
              this.$parent.cant_detalles =
                response.data.tep_det_nominacion.length;
              if (this.$parent.cant_detalles == 0) {
                this.$parent.form.estado = 1;
              }
              this.$swal({
                icon: "success",
                title: "Se eliminó el detalle de la nominación exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },
};
</script>
